export class Plans {
  public static global = {
    "planes": [
      {
        "id": 1,
        "name": "Lite",
        "description": "Con cobertura hasta $30,000 USD. Sin límite de edad.",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "image": "/assets/images/seguro-1-bg.png",
        "benefits": [
          "Atención médica por accidente - € 30,000.00",
          "Atención médica por enfermedad - € 30,000.00",
          "Copago - No aplica",
          "Atención médica por COVID-19 - € 30,000.00",
          "Medicina domiciliaria - Incluida",
          "Video consulta - Incluida",
          "Telemedicina - Incluida",
          "Línea de atención 24/7 - Incluida",
          "Hospitalización - Incluida",
          "Medicamentos por atención ambulatoria - € 400.00",
          "Medicamentos por hospitalización - € 500.00",
          "Odontología de urgencias - € 350.00",
          "Urgencias por enfermedad preexistente - € 5.400.00",
          "Embarazo - € 2.000.00",
          "Eventos ginecológicos/urinarios - € 150.00",
          "Pérdida de pasaporte - € 75.00",
          "Hotel por convalecencia monto máximo - € 1,000.00",
          "Hotel por convalecencia monto por día - € 50.00",
          "Extensión de hospedaje por cuarentena por COVID-19 positivo - € 2,000.00",
          "Estancia prolongada por día - € 50.00",
          "Compensación por invalidez o muerte - € 15,000.00",
          "Rastreo de equipaje - Incluido",
          "Reserva de tiquetes - Incluido",
          "Traslado de emergencia - Incluida",
          "Repatriación sanitaria - € 30,000.00",
          "Repatriación restos mortales - € 30,000.00",
          "Traslado de menores - € 600.00",
          "Traslado de un familiar - € 600.00",
          "Envio y transferencia de fondos - € 10,000.00",
          "Transferencia de fondos para fianza legal - € 15,000.00",
          "Asistencia legal por accidente de tránsito - € 5,000.00"
        ]
      },
      {
        "id": 2,
        "name": "Estándar",
        "description": "Cobertura hasta $50,000 USD. Sin límite de edad.",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "image": "/assets/images/seguro-2-bg.png",
        "benefits": [
          "Atención médica por accidente - € 50,000.00",
          "Atención médica por enfermedad - € 50,000.00",
          "Atención médica por Covid - € 30,000.00",
          "Copago - No aplica",
          "Telemedicina - Incluida",
          "Línea de atención 24/7 / conexión médico de cabecera - Incluida",
          "Hospitalización - Incluida",
          "Medicamento por enfermedad preexistencia - € 150.00",
          "Medicamentos por atención ambulatoria - € 500.00",
          "Medicamentos por hospitalización - € 1,000.00",
          "Odontología de urgencias incluye dentadura no natural - € 350.00",
          "Urgencia por enfermedad preexistente - € 700.00",
          "Compensación ortesis y prótesis - € 500.00",
          "Eventos ginecológicos / urinarios - € 150.00",
          "Pérdida de pasaporte - € 75.00",
          "Extensión de Hospedaje (incluye covid) - € 2,000.00",
          "Extensión de hospedaje por día - € 50.00",
          "Rastreo de equipaje - Incluido",
          "Perdida de equipaje - € 1,000.00",
          "Perdida de conexión - € 300.00",
          "Compensación por invalidez o muerte - € 15,000.00",
          "Traslado de emergencia - Incluida",
          "Repatriación sanitaria - € 30,000.00",
          "Repatriación restos mortales - € 30,000.00",
          "Traslado de menores - € 600.00",
          "Traslado de un familiar - € 600.00",
          "Envio y transferencia de fondos - € 1,000.00",
          "Transferencia de fondos para fianza legal - € 15,000.00",
          "Asistencia legal por accidente de tránsito - € 5,000.00",
          "Monto Máximo global - € 50,000.00"
        ]
      },
      {
        "id": 3,
        "name": "Estudiantil",
        "description": "Cobertura hasta $75,000 USD. Hasta 50 años.",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "image": "/assets/images/seguro-3-bg.png",
        "benefits": [
          "Atención médica por accidente - € 75,000.00",
          "Atención médica por enfermedad - € 375,000.00",
          "Copago - No aplica",
          "Atención médica por COVID-19 - € 375,000.00",
          "Medicina domiciliaria - Incluida",
          "Video consulta - Incluida",
          "Telemedicina - Incluida",
          "Línea de atención 24/7 - Incluida",
          "Asesoría psicológica telefónica - Incluida",
          "Hospitalización - Incluida",
          "Accidente por eventos laborales - € 500.00",
          "Medicamentos por atención ambulatoria - € 500.00",
          "Medicamentos por hospitalización - € 600.00",
          "Medicamentos por enfermedades preexistentes - € 200.00",
          "Atención ambulatoria por preexistencia - € 100.00",
          "Odontología de urgencias - € 400.00",
          "Urgencias por enfermedad preexistente - € 500.00",
          "Ortesis y prótesis - € 1,500.00",
          "Embarazo - € 42,000.00",
          "Eventos ginecológicos/urinarios - € 200.00",
          "Práctica básica de deporte - € 51,000.00",
          "Pérdida de pasaporte - € 370.00",
          "Hotel por convalecencia monto máximo - € 30,000.00",
          "Extensión de hospedaje por cuarentena por COVID-19 positivo - € 52,000.00",
          "Estancia prolongada por día - € 60.00",
          "Compensación por invalidez o muerte - € 10,000.00",
          "Reserva de tiquetes - Incluido",
          "Cambios de vuelos - Incluida",
          "Cancelación de viaje incluida por COVID-19 - € 5500.00",
          "Regreso anticipado del viaje - € 500.00",
          "Reprogramación del viaje - € 100.00",
          "Rastreo de equipaje - Incluida",
          "Demora entrega del equipaje - € 400.00 / $40 USD",
          "Pérdida de equipaje - € 1000.00 / $40 USD",
          "Daños al equipaje - € 350.00",
          "Retraso de vuelo - € 200.00",
          "Pérdida de llaves - € 3800.00",
          "Cancelación de vuelo - € 800.00",
          "Traslado de emergencia - Incluida",
          "Repatriación sanitaria - € 530,000.00",
          "Repatriación restos mortales - € 30,000.00",
          "Traslado de menores - € 81,000.00",
          "Traslado de un familiar - € 31,000.00",
          "Envío y transferencia de fondos - € 11,000.00",
          "Transferencia de fondos para fianza legal - € 10,000.00",
          "Asistencia legal por accidente de tránsito - € 1,300.00",
          "Atención veterinaria de urgencia - € 3,500.00"
        ]
      },
      {
        "id": 4,
        "name": "Gold",
        "description": "Cobertura hasta $100,000 USD. Hasta 79 años.",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "image": "/assets/images/seguro-4-bg.png",
        "benefits": [
          "Atención médica por accidentes - € 100,000.00",
          "Atención médica por enfermedad - € 100,000.00",
          "Copago - No aplica",
          "Atención médica por COVID-19 - € 100,000.00",
          "Medicina domiciliaria - Incluida",
          "Video consulta - Incluida",
          "Telemedicina - Incluida",
          "Línea de atención 24/7 - Incluida",
          "Asesoría psicológica telefónica - Incluida",
          "Hospitalización - Incluida",
          "Medicamentos por atención ambulatoria - € 400.00",
          "Medicamentos por hospitalización - € 500.00",
          "Medicamentos por enfermedades preexistentes - € 300.00",
          "Atención ambulatoria práctica - € 100.00",
          "Odontología de urgencias - € 400.00",
          "Urgencias por enfermedad preexistente - € 500.00",
          "Ortesis y prótesis - € 1,500.00",
          "Embarazo - € 12,000.00",
          "Eventos ginecológicos/urinarios - € 150.00",
          "Práctica profesional de deporte - € 53,000.00",
          "Práctica básica de deporte - € 1,000.00",
          "Pérdida de pasaporte - € 300.00",
          "Hotel por convalecencia monto máximo - € 11,000.00",
          "Hotel por convalecencia monto por día - € 100.00",
          "Extensión de hospedaje por cuarentena por COVID-19 positivo - € 33,000.00",
          "Estancia prolongada por día - € 100.00",
          "Compensación por invalidez o muerte - € 15,000.00",
          "Reserva de tiquetes - Incluido",
          "Cambios de vuelos - Incluido",
          "Cancelación de viaje por COVID-19 - € 5500.00",
          "Regreso anticipado del viaje - € 500.00",
          "Reprogramación del viaje - € 100.00",
          "Rastreo de equipaje - Incluida",
          "Demora entrega del equipaje - € 400.00 / $40 USD",
          "Pérdida de equipaje - € 1000.00 / $40 USD",
          "Daños al equipaje - € 100.00",
          "Pérdida de conexión - € 50.00",
          "Retraso de vuelo - € 5300.00",
          "Pérdida de llaves - € 52,000.00",
          "Cancelación de vuelo - € 800.00",
          "Traslado de emergencia - Incluida",
          "Repatriación sanitaria - € 50,000.00",
          "Repatriación restos mortales - € 30,000.00",
          "Traslado de menores - € 51,000.00",
          "Traslado de un familiar - € 1,000.00",
          "Envío y transferencia de fondos - € 31,000.00",
          "Transferencia de fondos para fianza legal - € 15,000.00",
          "Asistencia legal por accidente de tránsito - € 15,000.00",
          "Atención veterinaria de urgencia - € 500.00",
          "Medicamentos - € 150.00",
          "Hospitalización - € 100.00",
          "Servicios extra - € 150.00"
        ]
      },
      {
        "id": 5,
        "name": "Ideal",
        "description": "Cobertura hasta $75,000 USD. Hasta 79 años.",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "image": "/assets/images/seguro-4-bg.png",
        "benefits": [
          "Atención médica por accidente - € 75,000.00",
          "Atención médica por enfermedad - € 375,000.00",
          "Copago - No aplica",
          "Atención médica por COVID-19 - € 375,000.00",
          "Medicina domiciliaria - Incluida",
          "Video consulta - Incluida",
          "Telemedicina - Incluida",
          "Línea de atención 24/7 - Incluida",
          "Asesoría psicológica telefónica - Incluida",
          "Hospitalización - Incluida",
          "Accidente por eventos laborales - € 500.00",
          "Medicamentos por atención ambulatoria - € 500.00",
          "Medicamentos por hospitalización - € 600.00",
          "Medicamentos por enfermedades preexistentes - € 200.00",
          "Atención ambulatoria por preexistencia - € 100.00",
          "Odontología de urgencias - € 400.00",
          "Urgencias por enfermedad preexistente - € 500.00",
          "Ortesis y prótesis - € 1,500.00",
          "Embarazo - € 42,000.00",
          "Eventos ginecológicos/urinarios - € 200.00",
          "Práctica básica de deporte - € 51,000.00",
          "Pérdida de pasaporte - € 370.00",
          "Hotel por convalecencia monto máximo - € 30,000.00",
          "Extensión de hospedaje por cuarentena por COVID-19 positivo - € 52,000.00",
          "Estancia prolongada por día - € 60.00",
          "Compensación por invalidez o muerte - € 10,000.00",
          "Reserva de tiquetes - Incluido",
          "Cambios de vuelos - Incluida",
          "Cancelación de viaje incluida por COVID-19 - € 5500.00",
          "Regreso anticipado del viaje - € 500.00",
          "Reprogramación del viaje - € 100.00",
          "Rastreo de equipaje - Incluida",
          "Demora entrega del equipaje - € 400.00 / $40 USD",
          "Pérdida de equipaje - € 1000.00 / $40 USD",
          "Daños al equipaje - € 350.00",
          "Retraso de vuelo - € 200.00",
          "Pérdida de llaves - € 3800.00",
          "Cancelación de vuelo - € 800.00",
          "Traslado de emergencia - Incluida",
          "Repatriación sanitaria - € 530,000.00",
          "Repatriación restos mortales - € 30,000.00",
          "Traslado de menores - € 81,000.00",
          "Traslado de un familiar - € 31,000.00",
          "Envío y transferencia de fondos - € 11,000.00",
          "Transferencia de fondos para fianza legal - € 10,000.00",
          "Asistencia legal por accidente de tránsito - € 1,300.00",
          "Atención veterinaria de urgencia - € 3,500.00"
        ]
      }
    ]
  }
}
