export class Destinations {
  public static global = {
    "visas": [
      {
        "id": 1,
        "name": "Alemania",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "Un viaje a la historia y la innovación. Castillos, cervezas y una cultura apasionante.",
        "image": "/assets/images/caja1-bg.png",
        "benefits": [
          "Cobertura hasta $100,000 USD."
        ]
      },
      {
        "id": 2,
        "name": "Francia",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "Romance, arte y gastronomía. Explora París, viñedos y playas de la Costa Azul.",
        "image": "/assets/images/caja2-bg.png",
        "benefits": [
          "Cobertura hasta $100,000 USD."
        ]
      },
      {
        "id": 3,
        "name": "Canadá",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "Naturaleza deslumbrante y ciudades multiculturales. Aventura y hospitalidad en un solo lugar.",
        "image": "/assets/images/caja3-bg.png",
        "benefits": [
          "Cobertura hasta $100,000 USD."
        ]
      },
      {
        "id": 4,
        "name": "Estados Unidos",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "Un mosaico de experiencias. Desde la energía de las ciudades hasta la calma de sus parques.",
        "image": "/assets/images/caja4-bg.png",
        "benefits": [
          "Cobertura hasta $100,000 USD."
        ]
      },
      {
        "id": 5,
        "name": "Australia",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "Un mundo de maravillas naturales. Playas, fauna única y una cultura que invita a explorar.",
        "image": "/assets/images/caja2-bg.png",
        "benefits": [
          "Cobertura hasta $100,000 USD."
        ]
      },
      {
        "id": 6,
        "name": "Nueva Zelanda",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "Paisajes de película y aventuras al aire libre. Un rincón donde la naturaleza reina.",
        "image": "/assets/images/caja1-bg.png",
        "benefits": [
          "Cobertura hasta $100,000 USD."
        ]
      },
      {
        "id": 7,
        "name": "España",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "Rica herencia cultural con deliciosa gastronomía. Disfruta de tapas, flamenco y hermosos paisajes.",
        "image": "/assets/images/caja3-bg.png",
        "benefits": [
          "Cobertura hasta $100,000 USD."
        ]
      },
      {
        "id": 8,
        "name": "México",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "Colorido destino con historia rica y sabores únicos. Ruinas mayas, playas del Caribe y festivales increíbles.",
        "image": "/assets/images/caja4-bg.png",
        "benefits": [
          "Cobertura hasta $100,000 USD."
        ]
      },
      {
        "id": 9,
        "name": "Tailandia",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "Playas impresionantes y templos budistas. Cultura apasionante, comida deliciosa y mercados flotantes.",
        "image": "/assets/images/caja2-bg.png",
        "benefits": [
          "Cobertura hasta $100,000 USD."
        ]
      },
      {
        "id": 10,
        "name": "Asia",
        "type": "Visa de turismo",
        "sub": "Turismo, negocios, visitas familiares y transito hasta 6 meses.",
        "description": "Continente diverso con experiencias únicas. Tradición y modernidad en cada rincón, desde Tokio a Bali.",
        "image": "/assets/images/caja3-bg.png",
        "benefits": [
          "Cobertura hasta $100,000 USD."
        ]
      }
    ]
  }
}
