import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../enviroments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  public getCountries() {
    const headers = new HttpHeaders({
      'Accept-Language': 'es'
    });
    return this.http.get(`${environment.serverUrl}/api/visas?limit=300`, { headers });
  }

  public postCustomer(url: string, body: any) {
    return this.http.post(url, body);
  }

}
